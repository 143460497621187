<template>
  <div class="center">
    <el-form ref="Form" :model="formInline">
      <div class="search">
        <el-form-item prop="name">
          <el-input
            v-model="formInline.name"
            maxlength="20"
            placeholder="请输入商品名称"
          ></el-input>
        </el-form-item>
        <el-form-item prop="dictId">
          <el-select v-model="formInline.dictId" placeholder="请选择服务类别">
            <el-option
              v-for="item in options"
              :key="item.dictId"
              :label="item.dictValue"
              :value="item.dictId"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <div class="bottom" @click="getList">查询</div>
        <div class="bottom resive" @click="resive">重置</div>
      </div>
    </el-form>
    <div class="list">
      <div :key="item.serviceGoodId" class="table" v-for="item in tableList">
        <div
          class="left pointer"
          @click="todetail(item.serviceGoodId, item.onShelf, item)"
        >
          <div class="img">
            <el-image style="width: 100%; height: 100%" :src="item.mainGraph" />
          </div>
          <div class="text">
            <div class="first f-hide" style="width: 400px">{{ item.name }}</div>
            <div class="two">
              <span>{{
                "服务类别：" +
                item.serviceType +
                " | 单价：" +
                item.price +
                item.unit
              }}</span>
            </div>
            <div class="three">
              <i class="hy-icon-shijian1"></i>&nbsp;&nbsp;&nbsp;
              <span>{{ item.createTime }}</span>
            </div>
          </div>
        </div>
        <div class="right">
          <div>
            <span
              v-if="!item.onShelf"
              class="status"
              style="background: #909399"
              >{{ "已下架" }}</span
            >
            <span v-else class="status green">{{ "销售中" }}</span>
          </div>

          <el-button
            v-if="item.onShelf"
            type="primary"
            plain
            size="mini"
            style="width: 104px"
            @click="everyThing(item.serviceGoodId, item.dictId)"
            >{{ buttontext[item.dictId] || "立即下单" }}</el-button
          >
          <el-button
            type="primary"
            plain
            size="mini"
            style="width: 104px"
            @click="down(item.serviceGoodId)"
            >取消关注</el-button
          >
        </div>
      </div>
    </div>
    <div class="allpage">
      <el-pagination
        background
        :total="total"
        :page-size="formInline.pageSize"
        :current-page="formInline.pageNum"
        @current-change="handleCurrentChange"
        layout="prev, pager, next"
      >
      </el-pagination>
    </div>
    <el-dialog
      class="zyq1_dialog"
      title="发起运输"
      :visible.sync="dialogVisible"
      width="778px"
      :before-close="handleClose"
    >
      <div class="dialog_center" v-if="dialogVisible">
        <el-form
          :model="form"
          ref="form"
          :rules="rules"
          label-width="135px"
          class="demo-dynamic"
        >
          <el-row>
            <el-col :span="12">
              <el-form-item label="运输数量(吨)：" prop="num">
                <el-input
                  size="small"
                  oninput="value=value.replace(/[^\d^\.]/g,'')"
                  maxlength="5"
                  placeholder="请输入运输数量"
                  v-model="form.num"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="运输单价(元/吨)：" prop="price">
                <el-input
                  size="small"
                  oninput="value=value.replace(/[^\d^\.]/g,'')"
                  maxlength="5"
                  placeholder="请输入运输单价"
                  v-model="form.price"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item label="货物名称：" prop="goodName">
                <el-cascader
                  size="small"
                  placeholder="请选择货物名称"
                  v-model="form.goodName"
                  :props="{
                    value: 'dictName',
                    label: 'dictName',
                  }"
                  :options="optionstwo"
                >
                </el-cascader>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="付款类型：" prop="payType">
                <el-select
                  size="small"
                  v-model="form.payType"
                  placeholder="请选择付款类型"
                >
                  <el-option
                    v-for="item in ordertype"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item label="联系人：" prop="concateUser">
                <el-input
                  size="small"
                  placeholder="请输入联系人"
                  v-model="form.concateUser"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="联系方式：" prop="phone">
                <el-input
                  size="small"
                  oninput="value=value.replace(/[^\d^\.]/g,'')"
                  maxlength="11"
                  placeholder="请输入联系方式"
                  v-model="form.phone"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item label="起始地：" prop="startAddress">
                <el-cascader
                  size="small"
                  v-model="form.startAddress"
                  placeholder="请选择起始地"
                  :props="{
                    value: 'label',
                    label: 'label',
                  }"
                  :options="city"
                >
                </el-cascader>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="起始地详细地址：" prop="startAddressDetail">
                <el-input
                  size="small"
                  maxlength="100"
                  placeholder="请输入起始地详细地址"
                  v-model="form.startAddressDetail"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item label="目的地：" prop="endAddress">
                <el-cascader
                  size="small"
                  v-model="form.endAddress"
                  placeholder="请选择目的地"
                  :props="{
                    value: 'label',
                    label: 'label',
                  }"
                  :options="city"
                >
                </el-cascader>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="目的地详细地址：" prop="endAddressDetail">
                <el-input
                  size="small"
                  maxlength="100"
                  placeholder="请输入目的地详细地址"
                  v-model="form.endAddressDetail"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="handleClose">取 消</el-button>
        <el-button type="primary" @click="handleSure">发起用车需求</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import citys from "@/assets/json/citys";
import {
  getpartnerList,
  collection,
  serviceGoodCollection,
  placeTransportOrder,
  applyQuota,
} from "@/api/home";
import { reqDictType } from "@/api/hy";
const { baseUrl } = require("@/config/index.js");
import * as types from "@/store/action-types";
import Vue from "vue";
export default {
  data() {
    return {
      ordertype: [
        {
          value: 1,
          label: "先款后货",
        },
        {
          value: 2,
          label: "先货后款",
        },
      ],
      buttontext: {
        505: "申请额度",
        506: "发起运输",
      },
      city: citys,
      className: ["", "green", "red"],
      dialogVisible: false,
      total: 0,
      form: {
        // num:'',
        concateUser: "",
        phone: "",
        startAddress: "",
        // startAddressDetail:'',
        endAddress: "",
        // endAddressDetail:''
      },
      formInline: {
        userId: "",
        name: "",
        dictId: "",
        pageSize: 4,
        pageNum: 1,
      },
      options: [],
      optionstwo: [],
      tableList: [],
      rules: {
        num: [{ required: true, message: "请输入运输数量", trigger: "blur" }],
        price: [{ required: true, message: "请输入运输单价", trigger: "blur" }],
        goodName: [
          { required: true, message: "请选择货物名称", trigger: "change" },
        ],
        payType: [
          { required: true, message: "请选择付款类型", trigger: "change" },
        ],
        concateUser: [
          { required: true, message: "请输入联系人", trigger: "blur" },
          {
            pattern: /^[\u4e00-\u9fa5]{2,20}$/,
            message: "请输入2到20位汉字",
            trigger: "blur",
          },
        ],
        phone: [
          { required: true, message: "请输入联系方式", trigger: "blur" },
          {
            pattern: /^[1][0-9]{10}$/,
            message: "请输入有效手机号码",
            trigger: "blur",
          },
        ],
        startAddress: [
          { required: true, message: "请选择起始地", trigger: "change" },
        ],
        endAddress: [
          { required: true, message: "请选择目的地", trigger: "change" },
        ],
        // startAddressDetail: [
        //   { required: true, message: "请输入起始地详细地址", trigger: "blur" },
        // ],
        // endAddressDetail: [
        //   { required: true, message: "请输入目的地详细地址", trigger: "blur" },
        // ],
      },
    };
  },
  created() {
    this.formInline.userId = Vue.ls.get(types.userId);
    this.gettype();
    this.getList();
  },
  methods: {
    handleClose() {
      this.$refs.form.resetFields();
      this.dialogVisible = false;
    },
    handleSure() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          let obj = {
            ...this.form,
            endAddress: this.form.endAddress.join(","),
            goodName: this.form.goodName[0],
            startAddress: this.form.startAddress.join(","),
          };
          console.log(this.form.startAddress);

          placeTransportOrder(obj).then((res) => {
            if (res.code == 0) {
              this.$confirm("发起运输成功,是否前往物流订单中查看?", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
              })
                .then(() => {
                  this.$router.push({
                    path: "/uslogistics",
                  });
                })
                .catch(() => {
                  this.handleClose();
                  this.getList();
                });
            }
          });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    resive() {
      this.$refs.Form.resetFields();
      this.getList();
    },
    async getList() {
      const res = await serviceGoodCollection(this.formInline);
      if (res.code == 0) {
        this.tableList = res.data.list.map((item) => {
          return {
            ...item,
            price: item.price ? item.price : "面议",
            unit: item.price && item.unit ? "/" + item.unit : "",
            mainGraph: baseUrl + "third/third/ossGetFile/" + item.mainGraph,
            createTime: item.createTime
              ? item.createTime.replace("T", " ")
              : "",
          };
        });
        this.total = res.data.total;
      }
    },
    async gettype() {
      const res = await getpartnerList({
        dictType: "service_range",
        pageSize: 999,
        pageNum: 1,
      });
      if (res.code == 0) {
        this.options = res.data.list;
      }
      const ret = await reqDictType({
        dictType: "coal_type",
        pageSize: 999,
        pageNum: 1,
      });
      if (ret.code == 0) {
        let data = ret.data.children;
        for (let i = 0; i < data.length; i++) {
          let item = data[i];
          for (let j = 0; j < item.children.length; j++) {
            let sub = item.children[j];
            delete sub.children;
          }
        }
        this.optionstwo = data;
      }
    },
    //递归删除没有长度的child
    del(arr) {
      arr.forEach((item) => {
        if (item.children.length == 0) {
          delete item.children;
        } else {
          item.children = this.del(item.children);
        }
      });
      return arr;
    },
    async getdetail(serviceGoodId) {
      const res = await applyQuota({
        serviceGoodId,
      });
      if (res.code == 0) {
        if (res.data.applyStatus == 1) {
          this.$message.error("不可重复申请");
        } else if (res.data.url) {
          window.open(res.data.url);
        } else {
          this.$router.push({
            path: "/appliedQuota",
            query: {
              serviceGoodId,
            },
          });
        }
      } else {
        this.$message.error(res.message);
      }
    },
    everyThing(serviceGoodId, type) {
      console.log(serviceGoodId, type);
      if (type == 505) {
        if (!this.beforhandle("personalCenter:focusServiceGoods:appliedQuota"))
          return;
        this.getdetail(serviceGoodId);
      } else if (type == 506) {
        if (!this.beforhandle("personalCenter:focusServiceGoods:transport"))
          return;
        this.form.serviceGoodId = serviceGoodId;
        this.form.concateUser = window.localStorage.getItem("userName");
        this.form.phone = window.localStorage.getItem("phone");
        this.dialogVisible = true;
      } else {
        if (!this.beforhandle("personalCenter:focusServiceGoods:order")) return;
        this.$router.push({
          path: "/immediately",
          query: {
            serviceGoodId,
          },
        });
      }
    },
    down(id) {
      if (!this.beforhandle("personalCenter:focusServiceGoods:cancelAttention"))
        return;
      collection({ collection: false, serviceGoodIdList: [id] }).then((res) => {
        if (res.code == 0) {
          this.$message({
            type: "success",
            message: "取消关注成功",
          });
          this.getList();
        }
      });
    },
    handleCurrentChange(value) {
      this.formInline.pageNum = value;
      this.getList();
    },
    todetail(serviceGoodId, on, obj) {
      if (obj.serviceGoodId == "61012896765967") {
        return;
      }
      if (!this.beforhandle("personalCenter:focusServiceGoods:detail")) return;
      if (on) {
        this.$router.push({
          path: "/financialDetailsf",
          query: {
            serviceGoodId,
          },
        });
      } else {
        this.$message("商品已下架，无法查看商品详情。");
      }
    },
  },
};
</script>
<style lang="scss" scoped>
// .el-message-box {
//   width: 450px !important;
//   height: 218px !important;
//   background: #ffffff !important;
//   border-radius: 10px !important;
// }
// .el-message-box__header {
//   background: #f8f8f8;
// }
// .el-message-box__title {
//   font-size: 16px;
//   font-weight: 400;
//   color: #666666;
// }
// .el-message-box__content {
//   padding: 49px 119px;
//   color: #606266;
//   font-size: 14px;
// }
// .el-message-box__btns {
//   .el-button--primary {
//     background: linear-gradient(90deg, #2079fb, #4cb2fc);
//   }
// }
.zyq1_dialog {
  .el-dialog {
    border-radius: 10px;
  }
  .el-dialog__body {
    padding: 30px 27px 11px 20px;
  }
  .el-dialog__header {
    height: 53px;
    padding: 19px 25px;
    background: #f8f8f8;
    border-radius: 10px;
    .el-dialog__title {
      font-size: 16px;
      line-height: 16px;
      color: #666666;
    }
  }
  .el-dialog__footer {
    padding: 18px 25px 25px;
    .el-button--primary {
      background: linear-gradient(90deg, #2079fb, #4cb2fc);
    }
  }
  // input[type=text] {
  //   height: 34px;
  // }
  .demo-dynamic {
    width: 100%;
  }
  .el-input {
    width: 211px;
  }
  .dialog_center {
    // display: flex;
    // justify-content: center;
    height: 266px;
  }
}
.el-icon-arrow-right {
  position: static;
}
</style>
<style lang="scss" scoped>
::v-deep .el-col-12 {
  margin-bottom: 15px;
}
::v-deep .el-form-item {
  margin-bottom: 0;
  margin-left: 10px;
}
::v-deep .el-input__inner {
  width: 211px !important;
  height: 34px !important;
}
.center {
  width: 1195px;
  background: #ffffff;
  border: 1px solid #e5e5e5;
  box-shadow: -1px 0px 10px 0px rgba(0, 0, 0, 0.05);
  margin-bottom: 20px;
  .search {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    height: 70px;
    border-bottom: 1px solid #e5e5e5;
    .bottom {
      margin-left: 10px;
      height: 34px;
      text-align: center;
      padding: 0px 20px;
      border-radius: 4px;
      cursor: pointer;
      color: white;
      line-height: 34px;
      background: linear-gradient(90deg, #2079fb, #4cb2fc);
    }
    .resive {
      background: white;
      color: #aaaaaa;
      border: 1px solid #ccc;
    }
  }
  .list {
    padding: 16px;
    padding-bottom: 0px;
    .table {
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 110px;
      border-bottom: 1px solid #e5e5e5;
      padding-bottom: 16px;
      margin-bottom: 16px;
      .left {
        display: flex;
        .img {
          width: 167px;
          height: 97px;
          border-radius: 2px;
          overflow: hidden;
        }
        .text {
          margin-left: 17px;
          .first {
            height: 20px;
            line-height: 20px;
            font-size: 20px;
            font-family: PingFang SC;
            font-weight: 500;
            color: #40474e;
            margin-bottom: 13px;
          }
          .two {
            height: 14px;
            line-height: 14px;
            font-size: 14px;
            font-family: PingFang SC;
            font-weight: 500;
            color: #40474e;
            margin-bottom: 35px;
          }
          .three {
            display: flex;
            align-items: center;
            font-size: 12px;
          }
        }
      }
      .right {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-end;
        height: 93px;
        .status {
          padding: 4px 13px;
          height: 19px;
          line-height: 19px;
          font-size: 12px;
          color: #fff;
          background: #f87b28;
          border-radius: 10px;
        }
        .green {
          background: #67c23a !important;
        }
        .red {
          background: #f56c6c !important;
        }
        .do {
          width: 127px;
          height: 29px;
          line-height: 29px;
          text-align: center;
          background: #ffffff;
          border: 1px solid #b3d8ff;
          border-radius: 4px;
        }
      }
    }
  }
  .allpage {
    text-align: right;
    margin-bottom: 50px;
    margin-right: 6px;
  }
}
</style>
>
